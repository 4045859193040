<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="层级" prop="level">
                <a-input v-model="queryParam.level" placeholder="请输入层级" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="父级行政代码" prop="parentCode">
                <a-input v-model="queryParam.parentCode" placeholder="请输入父级行政代码" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="行政代码" prop="areaCode">
                  <a-input v-model="queryParam.areaCode" placeholder="请输入行政代码" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="邮政编码" prop="zipCode">
                  <a-input v-model="queryParam.zipCode" placeholder="请输入邮政编码" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="区号" prop="cityCode">
                  <a-input v-model="queryParam.cityCode" placeholder="请输入区号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="名称" prop="name">
                  <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="简称" prop="shortName">
                  <a-input v-model="queryParam.shortName" placeholder="请输入简称" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="组合名" prop="mergerName">
                  <a-input v-model="queryParam.mergerName" placeholder="请输入组合名" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="拼音" prop="pinyin">
                  <a-input v-model="queryParam.pinyin" placeholder="请输入拼音" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['super:system:nation:add']">
            <a-icon type="plus" />新增
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{float: 'right'}"
          icon="reload"
          @click="getList" /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :deptOptions="deptOptions"
        :statusOptions="statusOptions"
        @ok="getList"
        @select-tree="getTreeselect"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record)" v-hasPermi="['super:system:nation:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['super:system:nation:add']" />
          <a @click="$refs.createForm.handleAdd(record)" v-hasPermi="['super:system:nation:add']">
            <a-icon type="plus" />新增
          </a>
          <a-divider type="vertical" v-if="record.parentId != 0" v-hasPermi="['super:system:nation:remove']" />
          <a @click="handleDelete(record)" v-if="record.parentId != 0" v-hasPermi="['super:system:nation:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listNation, delNation } from '@/api/system/nation'
import CreateForm from './modules/CreateForm'

export default {
  name: 'Nation',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      // 机构树选项
      nationOptions: [],
      loading: false,
      queryParam: {
        level: null,
        parentCode: null,
        areaCode: null,
        zipCode: null,
        cityCode: null,
        name: null,
        shortName: null,
        mergerName: null,
        pinyin: null
      },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '层级',
          dataIndex: 'level',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '行政代码',
          dataIndex: 'areaCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询机构列表 */
    getList() {
      this.loading = true
      listNation(this.queryParam).then((response) => {
        this.list = this.handleTree(response.data, 'id', 'level')
        // this.list = this.handleTree(response.data, 'deptId')
        this.loading = false
      })
    },
    /** 查询菜单下拉树结构 */
    getTreeselect(row) {
      if (!row) {
        listNation().then((response) => {
          // this.deptOptions = this.handleTree(response.data, 'deptId')
          // this.nationOptions = this.handleTree(response.data, 'id', 'level')
          this.nationOptions = this.handleTree(response.data, 'id')
        })
      } else {
        // listDeptExcludeChild(row.deptId).then(response => {
        listNation(row.id).then((response) => {
          // this.deptOptions = this.handleTree(response.data, 'deptId')
          // this.nationOptions = this.handleTree(response.data, 'id', 'level')
          this.nationOptions = this.handleTree(response.data, 'id')
        })
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        level: null,
        parentCode: null,
        areaCode: null,
        zipCode: null,
        cityCode: null,
        name: null,
        shortName: null,
        mergerName: null,
        pinyin: null
      }
      this.handleQuery()
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const id = row.id
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + row.Name + '的数据',
        onOk() {
          return delNation(id).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
